import { PACKAGE_FRAGMENT, PAYMENT_METHOD_FRAGMENT, PROFILE_FRAGMENT, SUBSCRIPTION_FRAGMENT } from '#billing/fragment'

export const SUBSCRIPTION_QUERY = gql`
  ${SUBSCRIPTION_FRAGMENT}
  ${PACKAGE_FRAGMENT}
  query SubscriptionQuery($workspaceId: ID!) {
    subscription(workspaceId: $workspaceId) {
      ...Subscription
      package {
        ...Package
      }
    }
  }
`

export const PACKAGES_QUERY = gql`
  ${PACKAGE_FRAGMENT}
  query PackagesQuery {
    packages {
      ...Package
    }
  }
`

export const CREATE_SUBSCRIBE_PACKAGE_SESSION_MUTATION = gql`
  mutation CreateSubscribePackageSessionMutation($packageId: ID!, $workspaceId: ID!, $billingCycle: BillingCycleType!) {
    createSubscribePackageSession(packageId: $packageId, workspaceId: $workspaceId, billingCycle: $billingCycle) {
      success
      url
    }
  }
`

export const PAYMENT_METHOD_QUERY = gql`
  ${PAYMENT_METHOD_FRAGMENT}
  query PaymentMethodQuery($workspaceId: ID!) {
    paymentMethod(workspaceId: $workspaceId) {
      ...PaymentMethod
    }
  }
`

export const CREATE_PORTAL_SESSION_MUTATION = gql`
  mutation CreatePortalSessionMutation($workspaceId: ID!, $flow: PortalFlowType!) {
    createPortalSession(workspaceId: $workspaceId, flow: $flow) {
      success
      url
    }
  }
`

export const PROFILE_QUERY = gql`
  ${PROFILE_FRAGMENT}
  query ProfileQuery($workspaceId: ID!) {
    profile(workspaceId: $workspaceId) {
      ...Profile
    }
  }
`
