import { BillingPageTarget, PortalFlowEnum } from '../constant'
import type { SubscriptionWithPackage } from '#billing/types'

export const $useWorkspaceSubscription = () => {
  const { onResult, load, loading } = useLazySubscriptionQuery()
  const { currentWorkspace } = useWorkspaceSharedState()

  // Current subscription package name
  const subscription = ref<SubscriptionWithPackage | null | false>(false)
  onResult((result) => {
    if (result.data?.subscription) {
      subscription.value = result.data.subscription
    }
  })

  const getBillingUrl = (target: BillingPageTarget | null = null) => {
    return `/w/${currentWorkspace.value.handle}/billing` + String(target || '')
  }

  const isInBillingPage = () => {
    const currentUrl = window.location.pathname
    const billingUrl = getBillingUrl()

    return currentUrl.startsWith(billingUrl)
  }

  // Load subscription data
  if (currentWorkspace.value.id) {
    load(null, {
      workspaceId: currentWorkspace.value.id,
    })
  }

  // Watch for workspace changes to reload subscription data
  watch(currentWorkspace, () => {
    if (!currentWorkspace.value) {
      subscription.value = false
      return
    }

    load(null, {
      workspaceId: currentWorkspace.value.id,
    })
  })

  const { emit: showSelectPackageEmit } = useEventBus('show-select-package')
  const showSelectPackage = () => {
    if (isInBillingPage()) {
      showSelectPackageEmit()
    }

    navigateTo(getBillingUrl(BillingPageTarget.SELECT_PACKAGE))
  }

  return {
    subscription,
    loading,
    showSelectPackage,
    getBillingUrl,
  }
}

export const useWorkspaceSubscription = createSharedComposable($useWorkspaceSubscription)

// Update payment info composable
export const useUpatePaymentInfo = () => {
  const { mutate, error, loading: creatingSession } = useCreatePortalSessionMutation()
  const { currentWorkspace } = useWorkspaceSharedState()

  const updatePaymentMethod = async () => {
  // Create a portal session update payment method flow
    const data = await mutate({
      workspaceId: currentWorkspace.value.id,
      flow: PortalFlowEnum.PAYMENT_METHOD_UPDATE,
    })

    // Redirect to flow url
    if (data?.data?.createPortalSession.url) {
      window.location.href = data.data.createPortalSession.url
    } else {
      handleSingleGraphQLError(error.value, true)
    }
  }

  return {
    updatePaymentMethod,
    creatingSession,
  }
}
