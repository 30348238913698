import { SubscriptionStatus, activeStatuses } from '#billing/constant'

export const isActiveStatus = (status: string | null) => {
  return activeStatuses.includes(status as SubscriptionStatus)
}

export const isPastDueStatus = (status: string | null | undefined) => {
  return status === SubscriptionStatus.PAST_DUE
}

export const getRemainingDays = (endTimestamp: number) => {
  const now = Date.now()
  const remaining = (endTimestamp * 1000) - now
  const days = Math.floor(remaining / (1000 * 60 * 60 * 24))
  return days
}

export const formatTimestamp = (timestamp: number | null = null) => {
  if (!timestamp) return ''

  return new Date(timestamp * 1000).toLocaleDateString()
}
