export enum SubscriptionStatus {
  // inactive
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAUSED = 'paused',
  TRIAL_ENDED = 'trial_ended',
  // active
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
}

export enum BillingCycle {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export enum PortalFlowEnum {
  PAYMENT_METHOD_UPDATE = 'PAYMENT_METHOD_UPDATE',
  SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL'
}

export enum BillingPageTarget {
  SELECT_PACKAGE = '#select-package',
  SUCCESS = '#success',
}

export const activeStatuses = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.TRIALING,
  SubscriptionStatus.PAST_DUE,
]
