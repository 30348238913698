import type { BillingCycle, PortalFlowEnum } from '#billing/constant'
import { CREATE_PORTAL_SESSION_MUTATION, CREATE_SUBSCRIBE_PACKAGE_SESSION_MUTATION, PACKAGES_QUERY, PAYMENT_METHOD_QUERY, PROFILE_QUERY, SUBSCRIPTION_QUERY } from '#billing/schema'
import type { Package, PaymentMethod, Profile, SubscriptionWithPackage } from '#billing/types'

export const useSubscriptionQuery = (workspaceId: string) => {
  return useQuery<{ subscription: SubscriptionWithPackage }>(SUBSCRIPTION_QUERY, {
    workspaceId,
  })
}

export const useLazySubscriptionQuery = () => {
  return useLazyQuery<{ subscription: SubscriptionWithPackage }, { workspaceId: string }>(SUBSCRIPTION_QUERY)
}

export const usePackagesQuery = () => {
  return useLazyQuery<{ packages: Package[] }>(PACKAGES_QUERY)
}

export const useCreateSubscribePackageSessionMutation = () => {
  return useMutation<
    { createSubscribePackageSession: { success: boolean; url: string } },
    { packageId: string; workspaceId: string; billingCycle: BillingCycle }
  >(CREATE_SUBSCRIBE_PACKAGE_SESSION_MUTATION, defaultMutationOptions)
}

export const usePaymentMethodQuery = (workspaceId: string) => {
  return useQuery<{ paymentMethod: PaymentMethod }>(PAYMENT_METHOD_QUERY, {
    workspaceId,
  })
}

export const useCreatePortalSessionMutation = () => {
  return useMutation<{
    createPortalSession: { success: boolean; url: string }
  }, { workspaceId: string, flow: PortalFlowEnum }>(CREATE_PORTAL_SESSION_MUTATION, defaultMutationOptions)
}

export const useProfileQuery = (workspaceId: string) => {
  return useQuery<{ profile: Profile }>(PROFILE_QUERY, {
    workspaceId,
  })
}
