export const PACKAGE_FRAGMENT = gql`
  fragment Package on PackageType {
    id
    name
    description
    monthlyPrice
    yearlyPrice
    extraUserPrice
    includedUsers
  }
`

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment Subscription on SubscriptionType {
    id
    status
    placeholder
    billingCycle
    currentPeriodStart
    currentPeriodEnd
    trialEnd
    created
    cancelAt
  }
`

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethod on PaymentMethodType {
    id
    type
    ref
  }
`

export const PROFILE_FRAGMENT = gql`
  fragment Profile on ProfileType {
    id
    workspaceId
    activeUsersCount
  }
`
